import { FC } from 'react';
import { CopyrightFooter } from '@components/footer/copyrightFooter';
import FooterDesktop from '@components/footer/desktop/FooterDesktop';
import FooterDivider from '@components/footer/Divider';
import FooterMobile from '@components/footer/mobile/FooterMobile';
import { ModuleType } from '@shared/types/module.type';
import { useWindowSize } from '@shared/utils/hooks';
import { analytics } from '@shared/utils/segment.utils';
import breakpoint from '../../../shared/assets/breakpoint.json';
export interface IMenuLink {
  linkout: string;
  text: string;
}
const FooterProvider: FC = () => {
  const {
    width
  } = useWindowSize();
  const whatIEatMenu: IMenuLink[] = [{
    linkout: '/collection/breakfast',
    text: 'Breakfast'
  }, {
    linkout: '/collection/party',
    text: 'Party'
  }, {
    linkout: '/collection/healthy',
    text: 'Healthy'
  }, {
    linkout: '/collection/sweetness',
    text: 'Sweetness'
  }, {
    linkout: '/collection/cheeselover',
    text: 'Cheese Lover'
  }];
  const productMenu: IMenuLink[] = [{
    linkout: `/search?c=${ModuleType.Product}&f=pd_products_1`,
    text: 'อาหารสด'
  }, {
    linkout: `/search?c=${ModuleType.Product}&f=pd_products_2`,
    text: 'อาหารทานเล่น'
  }, {
    linkout: `/search?c=${ModuleType.Product}&f=pd_products_3`,
    text: 'ไส้กรอก / แฮม / เบคอน'
  }, {
    linkout: `/search?c=${ModuleType.Product}&f=pd_products_4`,
    text: 'อาหารพร้อมรับประทาน'
  }, {
    linkout: `/search?c=${ModuleType.Product}&f=pd_products_5`,
    text: 'ฮาลาล'
  }, {
    linkout: `/search?c=${ModuleType.Product}&f=pd_products_6`,
    text: 'อาหารเพื่อสุขภาพ'
  }, {
    linkout: `/search?c=${ModuleType.Product}&f=pd_products_7`,
    text: 'สินค้านำเข้า'
  }, {
    linkout: `/search?c=${ModuleType.Product}&f=pd_products_8`,
    text: 'อาหารพร้อมปรุง'
  }, {
    linkout: `/search?c=${ModuleType.Product}&f=pd_products_10`,
    text: 'ซีพี ดีไลท์'
  }];
  const otherOptionMenu: IMenuLink[] = [{
    linkout: '/collection',
    text: 'คอลเลกชัน'
  }, {
    linkout: '/news',
    text: 'ข่าวสาร'
  }, {
    linkout: '/tips-tricks',
    text: 'เคล็ดลับ'
  }, {
    linkout: '/food-service',
    text: 'ฟู้ดเซอร์วิส'
  }, {
    linkout: '/contact',
    text: 'ติดต่อเรา'
  }];
  const handleOnClickTracker = (title: string) => {
    analytics.track('Footer Click', {
      event: 'Footer Click',
      menu: title
    });
  };
  return <>
            {width > breakpoint.lg - 1 ? <FooterDesktop whatIEatMenu={whatIEatMenu} productMenu={productMenu} optionMenu={otherOptionMenu} onClickTracker={handleOnClickTracker} /> : <FooterMobile whatIEatMenu={whatIEatMenu} productMenu={productMenu} optionMenu={otherOptionMenu} onClickTracker={handleOnClickTracker} />}
            <FooterDivider />
            <CopyrightFooter onClickTracker={handleOnClickTracker} />
        </>;
};
export default FooterProvider;